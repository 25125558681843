<template>
  <div>
    <v-btn
      color="primary"
      class="text-none mr-2 btn"
      :loading="$global.state.uploading"
      @click="onButtonClick"
      large
    >
      <v-icon left> cloud_upload </v-icon>
      <h3>{{ buttonText }}</h3>
    </v-btn>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      :accept="accept"
      @change="onFileChanged"
    />
  </div>
</template>

<script>
export default {
  props: ["accept", "buttonText", "formData", "endPoint"],
  methods: {
    onButtonClick() {
      // this.isSelecting = true
      window.addEventListener(
        "focus",
        () => {
          // this.isSelecting = false
        },
        {
          once: true,
        }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      // do something
      this.$global.dispatch("handleFileUpload", {
        file: e.target.files[0],
        formDataName: this.formData,
        endPoint: this.endPoint,
      });
      this.$emit("model");
    },
  },
};
</script>
