<template>
  <div>
    <v-dialog v-model="$store.state.dialog" width="800" persistent>
      <v-card flat class="card">
        <v-toolbar outlined flat color="grey lighten-4">
          <v-toolbar-title
            >{{
              $store.state.itemDetails.dialogType == "add"
                ? $t("idhafh")
                : $t("edit")
            }}
            {{ this.$route.meta.single }}</v-toolbar-title
          >
          <v-spacer />
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form @submit.prevent="submit()" ref="form" v-model="valid">
          <v-container>
            <v-card outlined>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <h3 class="mb-2">
                      {{$t('alism-althlathy')}} <span class="required">*</span>
                    </h3>
                    <v-text-field
                      v-model="item.fullName"
                      :placeholder="$t('alism-althlathy')"
                      :rules="[$global.state.required()]"
                      required
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <h3 class="mb-2">
                      {{ $t("username") }}
                      <span class="required">*</span>
                    </h3>
                    <v-text-field
                      :hint="$t('english-only')"
                      v-model="item.userName"
                      :placeholder="$t('username')"
                      :rules="[$global.state.required()]"
                      required
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    v-if="$store.state.itemDetails.dialogType == 'add'"
                  >
                    <h3 class="mb-2">
                      {{ $t("password") }}
                      <span class="required">*</span>
                    </h3>
                    <v-text-field
                      v-model="item.password"
                      dir="ltr"
                      :placeholder="$t('password')"
                      :rules="[$global.state.required()]"
                      required
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <h3 class="mb-2">
                      {{ $t("alslahyh") }} <span class="required">*</span>
                    </h3>
                    <v-autocomplete
                      v-model="item.roleName"
                      :items="
                        $store.state.user.role == 'Admin'
                          ? $global.state.roles
                          : $global.state.roles_operation
                      "
                      item-text="name_ar"
                      item-value="name"
                      :rules="[$global.state.required()]"
                      :label="$t('alslahyh')"
                      hide-details="auto"
                      outlined
                      filled
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <h3 class="mb-2">
                      الوحدة التنظيمية <span class="required">*</span>
                    </h3>
                    <v-autocomplete
                      v-model="item.organizationId"
                      :items="$global.state.organizations"
                      item-text="name"
                      item-value="id"
                      :rules="[$global.state.required()]"
                      label="الوحدةالتنظيمية"
                      hide-details="auto"
                      outlined
                      filled
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <h3 class="mb-2">
                      {{ $t("alanwan-alwzyfy") }}
                    </h3>
                    <v-text-field
                      v-model="item.jobTitle"
                      :placeholder="$t('alanwan-alwzyfy')"
                      required
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <h3 class="mb-2">{{ $t("almjmwaat") }}</h3>
                    <v-autocomplete
                      v-model="userGroups"
                      :items="$global.state.groups"
                      item-text="name"
                      item-value="id"
                      :label="$t('almjmwaat')"
                      hide-details="auto"
                      outlined
                      multiple
                      filled
                      small-chips
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <MultipartFileUpload
                      accept="images/*"
                      formData="files"
                      endPoint="UploadFile"
                      :buttonText="$t('alswrh-alshkhsyh')"
                    />
                  </v-col>

                  <!-- <pre dir="ltr">
                                {{item}}
                                </pre> -->

                  <v-col cols="12" md="6" v-if="$global.state.path">
                    <v-card outlined v-if="$global.state.path">
                      <v-container>
                        <v-avatar tile size="100%">
                          <v-img :src="$config.BASE_URL_PROD + '/' + $global.state.path">
                            <v-btn
                              @click="$global.state.path = ''"
                              color="error"
                              icon
                            >
                              <v-icon>cancel</v-icon>
                            </v-btn>
                          </v-img>
                        </v-avatar>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-col cols="12" md="12">
              <v-row class="mt-2" justify="end">
                <v-btn
                  @click="close()"
                  x-large
                  color="error"
                  class="mx-2 btn"
                  outlined
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  :disabled="!valid"
                  type="submit"
                  :loading="$global.state.loading"
                  x-large
                  color="primary"
                  class="btn"
                  >{{ $t("save") }}</v-btn
                >
              </v-row>
            </v-col>
            <!-- <pre dir="ltr">
                      {{userGroups }}
                    </pre>
                    <pre dir="ltr">
                      {{item }}
                    </pre> -->
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MultipartFileUpload from "@/components/MultipartFileUpload.vue";
export default {
  components: {
    MultipartFileUpload,
  },
  data() {
    return {
      item: {},
      valid: false,
      userGroups: [],
      gender: [
        {
          name: this.$t("male"),
          id: true,
        },
        {
          name: this.$t("female"),
          id: false,
        },
      ],
    };
  },

  mounted() {
    this.reset();
    this.$global.dispatch(`getOrganization`);
    this.$eventBus.$on("fill-fields", () => {
      this.reset();
      console.log(this.$store.state.itemDetails);
      this.item = this.$store.state.itemDetails;
      this.item.groups = [];
      this.item.provinceId = this.$store.state.itemDetails.province.id;
      this.item.roleName = this.$store.state.itemDetails.role.name;
      console.log(this.$store.state.itemDetails);
      for (
        let i = 0;
        i < this.$store.state.itemDetails.userGroups.length;
        i++
      ) {
        this.userGroups[i] =
          this.$store.state.itemDetails.userGroups[i].groupId;
      }
      // this.item.userGroups = this.$store.state.itemDetails.userGroups.map(v => {
      //     return v.groupId;
      // })
      this.item.organizationId = this.$store.state.itemDetails.organization.id;
      this.$global.state.path = this.$store.state.itemDetails.imageUrl;
    });
  },

  methods: {
    reset() {
      this.item = {
        fullName: "",
        userName: "",
        // email: null,
        password: "",
        // phoneNumber: null,
        imageUrl: "",
        address: null,
        jobTitle: "",
        provinceId: "24e45ff2-e357-4ff3-b2d9-755099c6986c",
        organizationId: "",
        roleName: "",
        userGroups: [],
        groups: [],
        gender: true,
        birthday: new Date("1999-01-01"),
        areaId: null,
      };
      this.$global.state.path = "";
      this.userGroups = [];
    },

    submit() {
      // this.item.phoneNumber = this.item.phoneNumber.replace(/,/g, "");
      this.item.imageUrl = this.$global.state.path;
      // this.item.birthday = new Date(this.item.birthday)
      //     .toISOString()
      //     .split("T")[0];

      if (this.$store.state.itemDetails.dialogType == "add") {
        this.addItem();
      } else {
        this.editItem();
      }
    },

    addItem() {
      this.$global.state.loading = true;
      for (let i = 0; i < this.userGroups.length; i++) {
        this.item.userGroups[i] = {
          groupId: this.userGroups[i],
        };
      }
      this.$http
        .post(`${this.$route.meta.endPoint}`, this.item)
        .then((res) => {
          this.$service.success(this.$t("tm-alidhafh-bnjah"));
          this.$eventBus.$emit(`refresh`);
          this.close();
        })
        .catch((err) => {
          this.$service.faild();
          console.log(err.data.errors);
        })
        .finally(() => (this.$global.state.loading = false));
    },

    editItem() {
      this.$global.state.loading = true;
      for (let i = 0; i < this.userGroups.length; i++) {
        this.item.groups.push({ id: this.userGroups[i] });
        console.log("this.item.groups[i]");
        console.log(this.item.groups[i]);
      }
      this.item.userId = this.item.id
      this.$http
        .put(`${this.$route.meta.endPoint}/${this.item.id}`, this.item)
        .then((res) => {
          this.close();

          this.$service.success(this.$t("tm-altadyl-bnjah"));
          this.$eventBus.$emit(`refresh`);
        })
        .catch((err) => {
          this.$service.faild(err.response.data.message);
        })
        .finally(() => (this.$global.state.loading = false));
    },

    close() {
      this.$store.commit("setDialog");
      this.reset();
      this.resetValidation();
      this.$store.commit("setItemDetails", {});
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
